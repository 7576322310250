import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './About';
import Contact from './Contact';
import Careers from './Careers';
import PrivacyPolicy from './PrivacyPolicy';
import Login from './Login';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="mb-4 border rounded-lg">
      <button
        className="flex justify-between items-center w-full p-4 text-left bg-white hover:bg-gray-50 focus:outline-none"
        onClick={onClick}
      >
        <span className="font-semibold">{question}</span>
        <svg
          className={`w-6 h-6 transition-transform transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="p-4 bg-gray-50">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const HomePage = () => {
  const [openFAQ, setOpenFAQ] = useState(0);

  const offerings = [
    { title: "Market Intelligence", description: "Access real-time market data and deep insights. Our platform empowers you to stay ahead with up-to-the-minute information and comprehensive analysis, driving informed decisions" },
    { title: "Data Integration", description: "Seamlessly integrate diverse data sources into one unified platform. Optimize your workflow and enhance data accuracy by centralizing all your information for quick and effective decision-making" },
    { title: "Strategic Forecasting", description: "Predict market movements and trends with advanced forecasting tools. Make proactive decisions that position you for success in an ever-changing market landscape" },
    { title: "Trading Tools", description: "Utilize cutting-edge trading tools designed for precision and speed. Execute trades efficiently, monitor market movements, and manage risks with our state-of-the-art platform" },
  ];

  const benefits = [
    {
      title: "Advanced AI/ML Algorithms",
      description: "Harness the power of sophisticated AI and machine learning algorithms, developed through rigorous research, to drive smarter decision-making and superior market predictions",
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
        </svg>
      )
    },
    {
      title: "Mathematical Precision",
      description: "Our solutions are built on robust mathematical models, ensuring accuracy and reliability in every analysis, forecast, and trading strategy",
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      )
    },
    {
      title: "Rapid Innovation",
      description: "Stay at the forefront of financial innovation with our cutting-edge AI/ML technologies, designed to optimize performance and deliver unparalleled insights",
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      )
    },
  ];

  const faqs = [
    {
      question: "What kind of market insights does Qunoe Terminal provide?",
      answer: "Qunoe Terminal offers AI-powered market analysis, delivering real-time insights and predictive analytics to help you stay ahead of market trends"
    },
    {
      question: "How does Qunoe integrate and manage my data?",
      answer: "Our platform seamlessly integrates diverse data sources into a unified system, ensuring accurate, actionable insights that are easily accessible"
    },
    {
      question: "Can Qunoe help with forecasting market trends?",
      answer: "Yes, Qunoe utilizes advanced algorithms and mathematical models to provide precise market forecasting, empowering you to make informed strategic decisions"
    },
    {
      question: "What makes Qunoe's trading tools stand out?",
      answer: "Qunoe Terminal offers cutting-edge trading tools designed for accuracy, speed, and efficiency, enabling you to execute your strategies with confidence."
    },
    {
      question: "How does Qunoe leverage AI in its solutions?",
      answer: "Qunoe applies deep AI/ML technology, using sophisticated algorithms and mathematical precision to deliver innovative and effective market strategies"
    },
    {
      question: "What industries can benefit most from Qunoe's solutions?",
      answer: "Qunoe's AI-powered insights and tools are highly adaptable, benefiting a wide range of industries including finance, technology, manufacturing, and more, by enhancing decision-making and operational efficiency"
    }
  ];

  return (
    <>
      <main className="flex-grow">
        <div className="bg-red-800 text-white py-12 md:py-16 px-4 text-center">
          <div className="container mx-auto max-w-4xl">
            <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-4xl font-bold mb-4 leading-tight">
              Qunoe Research Terminal
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl mb-8">
            A financial research framework offering quick access to deep market intelligence and insights, powered by advanced financial artificial intelligence, mathematical algorithms and statistical models, enabling decision-makers to swiftly turn knowledge into decisive action
            </p>
          </div>
        </div>

        <div className="container mx-auto px-4 py-12 md:py-16">
          <h2 className="text-2xl font-bold text-red-800 mb-8 text-center">Financial AI Driven Market Research</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {offerings.map((offering, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold text-red-800 mb-4">{offering.title}</h3>
                <p className="text-gray-600">{offering.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-gray-100 py-12 md:py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold text-red-800 mb-8 text-center">Benefits of Adopting Qunoe Terminal</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex items-start">
                  <div className="flex-shrink-0 mr-4">
                    <div className="bg-red-800 rounded-full p-3 text-white">
                      {benefit.icon}
                    </div>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-red-800 mb-2">{benefit.title}</h3>
                    <p className="text-gray-600">{benefit.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="py-12 md:py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold text-red-800 mb-8 text-center">Frequently Asked Questions</h2>
            <div className="max-w-3xl mx-auto">
              {faqs.map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openFAQ === index}
                  onClick={() => setOpenFAQ(openFAQ === index ? -1 : index)}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <header className="bg-white shadow-md">
          <div className="container mx-auto px-4 py-4 flex flex-wrap justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-[#b91c1c] hover:text-white hover:bg-[#b91c1c] px-2 py-1 rounded transition-colors duration-300" onClick={closeMenu}>Qunoe</Link>
            <button
              className="md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            </button>
            <nav className={`${isMenuOpen ? 'block' : 'hidden'} md:flex md:items-center w-full md:w-auto mt-4 md:mt-0`}>
              <ul className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
                <li><Link to="/" className="text-gray-600 hover:text-gray-800 inline-block py-2" onClick={closeMenu}>Home</Link></li>
                <li><Link to="/about" className="text-gray-600 hover:text-gray-800 inline-block py-2" onClick={closeMenu}>About</Link></li>
                <li><Link to="/careers" className="text-gray-600 hover:text-gray-800 inline-block py-2" onClick={closeMenu}>Careers</Link></li>
                <li><Link to="/contact" className="text-gray-600 hover:text-gray-800 inline-block py-2" onClick={closeMenu}>Contact</Link></li>
                <li><Link to="/login" className="bg-[#b91c1c] text-white px-4 py-2 rounded inline-block" onClick={closeMenu}>Log In</Link></li>
              </ul>
            </nav>
          </div>
        </header>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/login" element={<Login />} />
        </Routes>

        <footer className="bg-black text-white py-8">
          <div className="container mx-auto px-4">
            <div className="flex flex-col items-center md:flex-row md:justify-between mb-4">
              <Link to="/" className="text-2xl font-bold hover:text-gray-300 mb-4 md:mb-0" onClick={closeMenu}>Qunoe</Link>
              <div className="flex flex-col items-center md:flex-row md:space-x-4 space-y-2 md:space-y-0">
                <Link to="/privacy-policy" className="hover:underline text-center" onClick={closeMenu}>Privacy Policy</Link>
                <span className="text-center">Email: contact@qunoe.com</span>
              </div>
            </div>
            <div className="text-sm text-center mt-4">
              © 2024 Qunoe. All Rights Reserved.
            </div>
            <div className="mt-4 text-xs text-center">
              <span className="font-bold">Disclaimer:</span> The materials presented on this website, its social media platforms, or any platforms where Qunoe Research Terminal shares its research data are intended solely for illustrative and discussion purposes and do not constitute an offer, suggestion or recommendation. Any offering will be made exclusively through the delivery of a confidential offering memorandum to appropriate parties.
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
};

export default App;