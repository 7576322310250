import React from 'react';
import { Link } from 'react-router-dom';

const Careers = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h1 className="text-3xl font-bold text-red-800 mb-8 text-center">Careers at Qunoe</h1>
            <p className="mb-8 text-gray-700 leading-relaxed">
              At Qunoe Terminal, we're not just creating advanced market analysis tools; we're shaping a future where mathematical precision and AI-driven insights empower businesses to achieve extraordinary outcomes. We're seeking passionate, innovative, and driven individuals who are eager to make a real impact in the world of financial technology, data science, and quantitative analysis. Join us in transforming how businesses navigate markets and make decisions with the power of cutting-edge technology.
            </p>

            <h2 className="text-2xl font-bold text-red-800 mb-4">Why Work at Qunoe?</h2>
            <ul className="list-disc pl-5 mb-8 space-y-4 text-gray-700">
              <li>
                <strong>Innovative Environment:</strong> Join a team that thrives on creativity and innovation. At Qunoe, we push the boundaries of what's possible, working on projects that challenge the status quo and redefine industries.
              </li>
              <li>
                <strong>Global Opportunities:</strong> Our team is a diverse, global community of experts. With projects spanning across multiple countries and industries, you'll have the opportunity to collaborate with top talent from around the world and expand your professional horizons.
              </li>
              <li>
                <strong>Growth and Development:</strong> We are committed to your professional growth. At Qunoe, you'll have access to ongoing learning opportunities, mentorship from industry leaders, and the chance to work on cutting-edge technologies that will keep you at the forefront of the industry.
              </li>
              <li>
                <strong>Impactful Work:</strong> At Qunoe, your work will have a direct impact on businesses worldwide. Whether you're developing AI solutions, crafting innovative software, or helping clients transform their operations, you'll be contributing to projects that drive real-world change.
              </li>
              <li>
                <strong>Collaborative Culture:</strong> We believe that the best ideas come from collaboration. At Qunoe, you'll be part of a supportive and inclusive team that values diverse perspectives and encourages everyone to share their ideas and expertise.
              </li>
            </ul>

            <h2 className="text-2xl font-bold text-red-800 mb-4">Who We're Looking For</h2>
            <p className="mb-8 text-gray-700 leading-relaxed">
              We're looking for individuals who are not only experts in their field but are also curious, adaptable, and eager to push the boundaries of what's possible. Whether you're a seasoned professional or just beginning your career, if you have a passion for quantitative analysis, AI-driven market insights, or financial technology, and are excited about the opportunity to work on groundbreaking projects, Qunoe Terminal could be the perfect place for you. Join us in revolutionizing the way businesses make data-driven decisions.
            </p>

            <h2 className="text-2xl font-bold text-red-800 mb-4">Join Us</h2>
            <p className="mb-8 text-gray-700 leading-relaxed">
              Are you ready to make a difference? Join Qunoe and be part of a team that's transforming the way businesses operate through the power of AI. Together, we can create the future of technology.
            </p>

            <div className="text-center">
              <p className="text-lg font-semibold text-gray-800">
                <Link to="/contact" className="text-red-800 hover:text-red-600 underline">Contact us</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;