import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white shadow-md rounded-lg p-8 max-w-5xl mx-auto">
          <h1 className="text-3xl font-bold mb-6 text-center text-red-800">Privacy Policy</h1>
          
          <p className="text-sm text-gray-600 mb-6 text-center">Last updated: {new Date().toLocaleDateString()}</p>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Introduction</h2>
            <p className="mb-4">
              At Qunoe, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our services or visit our website.
            </p>
            <p className="mb-4">
              This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p className="mb-4">
              We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Interpretation and Definitions</h2>
            <h3 className="text-xl font-semibold mb-2 text-red-800">Interpretation</h3>
            <p className="mb-4">
              The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>
            <h3 className="text-xl font-semibold mb-2 text-red-800">Definitions</h3>
            <p className="mb-4">
              For the purposes of this Privacy Policy:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
              <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Qunoe.</li>
              <li><strong>Service</strong> refers to the Website.</li>
              <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
              <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
              <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
              <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
              <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
              <li><strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Information We Collect</h2>
            <p className="mb-4">
              We may collect various types of information, including but not limited to:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Personal identification information (Name, email address, phone number, etc.)</li>
              <li>Usage data (How you interact with our services)</li>
              <li>Technical data (IP address, browser type, device information)</li>
            </ul>

            <h3 className="text-xl font-semibold mb-2 text-red-800">Usage Data</h3>
            <p className="mb-4">
              Usage Data is collected automatically when using the Service.
            </p>
            <p className="mb-4">
              Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
            </p>
            <p className="mb-4">
              When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
            </p>
            <p className="mb-4">
              We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
            </p>

            <h3 className="text-xl font-semibold mb-2 text-red-800">Information from Third-Party Social Media Services</h3>
            <p className="mb-4">
              The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Google</li>
              <li>Facebook</li>
              <li>Instagram</li>
              <li>Twitter</li>
              <li>LinkedIn</li>
            </ul>
            <p className="mb-4">
              If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
            </p>
            <p className="mb-4">
              You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
            </p>

            <h3 className="text-xl font-semibold mb-2 text-red-800">Tracking Technologies and Cookies</h3>
            <p className="mb-4">
              We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
              <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
            </ul>
            <p className="mb-4">
              Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">How We Use Your Information</h2>
            <p className="mb-4">
              We use the collected information for various purposes, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Providing and maintaining our services</li>
              <li>Improving and personalizing user experience</li>
              <li>Communicating with you about our services</li>
              <li>Complying with legal obligations</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Data Protection</h2>
            <p className="mb-4">
              We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Your Rights</h2>
            <p className="mb-4">
              You have certain rights regarding your personal data, including the right to access, correct, or delete your personal information. To exercise these rights, please contact us using the information provided at the end of this policy.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Children's Privacy</h2>
            <p className="mb-4">
              Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
            </p>
            <p className="mb-4">
              If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Links to Other Websites</h2>
            <p className="mb-4">
              Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.
            </p>
            <p className="mb-4">
              We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Changes to this Privacy Policy</h2>
            <p className="mb-4">
              We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="mb-4">
              We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
            </p>
            <p className="mb-4">
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Disclaimer</h2>
            <p className="mb-4">
              The materials presented on this website, its social media platforms, or any platforms where Qunoe Research Terminal shares its research data are intended solely for illustrative and discussion purposes and do not constitute an offer, suggestion or recommendation. Any offering will be made exclusively through the delivery of a confidential offering memorandum to appropriate parties.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Consent</h2>
            <p className="mb-4">
              By accessing this website, you consent to the terms outlined in our Privacy Policy.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-red-800">Contact Us</h2>
            <p className="mb-4">
              If you have any questions about this Privacy Policy, You can contact us:
            </p>
            <p className="mb-4">
              By visiting the <Link to="/contact" className="text-blue-600 hover:underline">contact</Link> page on our website.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;