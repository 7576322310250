import React from 'react';

const About = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h1 className="text-3xl font-bold text-red-800 mb-8 text-center">About Qunoe</h1>
            <div className="space-y-6 text-gray-700">
              <p className="text-lg leading-relaxed">
                Qunoe Research Terminal is revolutionizing financial research and decision-making by harnessing the power of advanced mathematical and statistical methods. Our platform is designed to provide users with precise, data-driven insights, leveraging complex algorithms and quantitative models that have been meticulously developed and tested. These methods enable us to analyze vast amounts of market data, uncover hidden patterns, and generate forecasts that are both accurate and actionable. In a world where market conditions can change in an instant, Qunoe Terminal ensures that you stay ahead with the most reliable financial research and information available.
              </p>
              <p className="text-lg leading-relaxed">
                At the core of Qunoe Terminal's offering is a deep integration of AI and machine learning technologies, seamlessly blended with rigorous statistical frameworks. This unique combination enables us to process and interpret data at an unprecedented scale, providing users with insights that are not just based on historical trends but also on predictive analytics. Our platform goes beyond simple data aggregation, delivering sophisticated analyses that are tailored to the specific needs of our users, whether they are managing investment portfolios, executing trades, or developing long-term financial strategies.
              </p>
              <p className="text-lg leading-relaxed">
                Our commitment to mathematical and statistical precision is what sets Qunoe Terminal apart from other market analysis tools. We understand that in the fast-paced world of finance, accuracy is paramount. That's why every model and algorithm we deploy is grounded in the latest advancements in quantitative finance, ensuring that our users have access to the most dependable forecasts and insights. Whether it's through probability models, regression analyses, or other statistical techniques, Qunoe Terminal provides the tools needed to make informed, confident decisions in any market environment.
              </p>
              <p className="text-lg leading-relaxed">
                Qunoe Terminal is more than just a tool—it's a partner in your financial journey. By delivering insights rooted in deep mathematical analysis and cutting-edge statistical methods, we empower decision-makers across industries to navigate complex markets with clarity and confidence. Our platform is designed to adapt to your specific needs, offering a level of customization and precision that is unmatched in the industry. As the financial landscape continues to evolve, Qunoe Terminal stands ready to provide the analytical power needed to turn data into decisive action, ensuring your success in an increasingly competitive world.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;