import React from 'react';

const Contact = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-red-800 mb-4 text-center">Contact Us</h1>
      <p className="text-xl text-center mb-8">contact@qunoe.com</p>
      <div className="w-full max-w-5xl mx-auto">
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSfzKB-b0yNTzqttYCg_o51PXc1lIlTCoxSUXjA2CWzJDoBrxA/viewform?embedded=true" 
          width="100%" 
          height="1000" 
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0"
          title="Contact Form"
          className="w-full"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default Contact;